@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("/node_modules/pretendard/dist/web/static/pretendard.css");
@font-face {
    font-family: "Catamaran";
    src: url("/node_modules/@fontsource/catamaran/files/catamaran-latin-500-normal.woff2")
        format("woff2");
    unicode-range: U+0061-007A, U+0041-005A;
}

:root {
  
}


html {
    scroll-behavior: smooth;
}
body {
    margin: 0;
    font-family: "Catamaran", Pretendard, -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@media screen and (max-aspect-ratio: 1219/685) {
    .background_video {
        width: auto !important;
        height: 100vh !important;
    }
}

ul.dotsWrap {
    display: flex !important;
    flex-direction: row;
    margin: auto;
    width: fit-content;
}

.dotsWrap li {
    width: fit-content;
    opacity: 0.6;
    margin-right: 1rem;
    line-height: 2;
}
.dotsWrap li:last-child {
    margin-right: 0;
}

.dotsWrap li.slick-active {
    opacity: 1;
    font-weight: 900;
    border-bottom: 3px solid #000;
}

.main-tab {
}

.main-tab.tab-selected .bg-active {
    background: rgb(153 27 27) !important;
}

.main-tab.tab-selected .active {
    color: rgb(153 27 27) !important;
}

.slick-slide > div {
    margin: 0 10px;
}
.slick-list {
    margin: 0 -10px;
}
/* Looper styles */
@keyframes slideAnim {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}

.looper {
    width: 100%;
    overflow: hidden;
}

.looper__innerList {
    display: flex;
    justify-content: center;
    width: fit-content;
}

.looper__innerList[data-animate="true"] .looper__listInstance {
    animation: slideAnim linear infinite;
}

.looper__listInstance {
    display: flex;
    width: max-content;

    animation: none;
}

.contentBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    margin: 10px;
    padding: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 16px;
}
.contentBlock--one {
    width: 120px;
    background: rgba(255, 255, 255, 0.05);
    font-weight: 600;
    font-size: 18px;
}
.contentBlock--one:last-of-type {
    color: var(--green);
    margin-right: 36px;
}
.contentBlock--two {
    color: var(--green);
    font-size: 48px;
    background: rgba(255, 255, 255, 0.05);
}
.contentBlock {
    width: 12.5%;
}
.contentBlock img {
    object-fit: contain;
}
